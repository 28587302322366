import * as React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/core/Layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="mx-6 flex flex-col gap-y-4">
        <h1 className="text-6xl font-bold text-primary">404 Page not found</h1>
        <p className="text-primary opacity-60">
          The link to this page might not exist or be out of date.
        </p>
        <p className="text-primary opacity-60">
          I'm Sorry we couldn’t find what you were looking for. Why not go back
          to the{' '}
          <Link className="font-bold hover:text-accent" to="/">
            home page
          </Link>
          ?
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
